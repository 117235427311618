import FeedAppSettingsButton from "@/apps/list/app/feed/settings-button/FeedAppSettingsButton"
import FeedEmptyStateIcon from "@/core/ui/images/empty-state-illustrations/feed-emptystate.svg"
import CreatePostWithAIButton from "@/post/list/empty-state/CreatePostWithAIButton"
import { AIFeedEmptyStateFragment$key } from "@/post/list/empty-state/__generated__/AIFeedEmptyStateFragment.graphql"
import { DiscoButton, DiscoEmptyState } from "@disco-ui"
import { graphql, useFragment } from "react-relay"

interface Props {
  feedKey: AIFeedEmptyStateFragment$key
}

function AIFeedEmptyState({ feedKey }: Props) {
  const feed = useFragment<AIFeedEmptyStateFragment$key>(
    graphql`
      fragment AIFeedEmptyStateFragment on Feed {
        id
        app {
          id
          visibility
          customAppDescription
          ...FeedAppSettingsButtonFragment
        }
      }
    `,
    feedKey
  )
  if (!feed.app.customAppDescription) {
    return (
      <DiscoEmptyState
        testid={"AIFeedEmptyState.add-description"}
        icon={<FeedEmptyStateIcon />}
        title={`Add a Feed Description for customized Disco AI suggestions.`}
        subtitle={`Receive post suggestions to engage your community.`}
        buttons={
          <FeedAppSettingsButton appKey={feed.app}>
            {(buttonProps) => (
              <DiscoButton {...buttonProps} leftIcon={"add"}>
                {"Feed Description"}
              </DiscoButton>
            )}
          </FeedAppSettingsButton>
        }
      />
    )
  }

  return (
    <DiscoEmptyState
      icon={<FeedEmptyStateIcon />}
      testid={"AIFeedEmptyState.empty-state.create-post-with-ai"}
      title={`No posts yet`}
      subtitle={`Add your first post to start engaging your community!`}
      buttons={<CreatePostWithAIButton feedId={feed.id} />}
    />
  )
}

export default AIFeedEmptyState
