import { OrganizationCurrency } from "@/admin/appearance/__generated__/AdminAppearancePageMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalModal } from "@/core/context/GlobalModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import { CURRENCY_LABELS } from "@/organization/util/organizationConstants"
import { ConnectStripeModalContent_UpdateCurrencyMutation } from "@/payment/stripe-integration/modal/__generated__/ConnectStripeModalContent_UpdateCurrencyMutation.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import {
  DiscoButton,
  DiscoFormControl,
  DiscoIcon,
  DiscoSelect,
  DiscoSelectSkeleton,
  DiscoText,
} from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { graphql } from "relay-runtime"

type Props = TestIDProps

function ConnectStripeModalContent({ testid = "ConnectStripeModalContent" }: Props) {
  const activeOrganization = useActiveOrganization()!
  const modal = useGlobalModal("connectStripe")
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const form = useFormStore<ConnectStripeModalContent_UpdateCurrencyMutation>(
    graphql`
      mutation ConnectStripeModalContent_UpdateCurrencyMutation(
        $input: UpdateOrganizationCurrencyInput!
        $returnTo: String!
      ) {
        response: updateOrganizationCurrency(input: $input) {
          node {
            id
            currency
            stripeOauthAuthorizeUrl(returnTo: $returnTo)
            stripeAccountId
            hasStripeConnection
          }
          errors {
            field
            message
          }
        }
      }
    `,
    { currency: activeOrganization.currency },
    { requireChangeToSubmit: false }
  )

  return (
    <Form
      id={testid}
      testid={testid}
      onSubmit={handleSubmit}
      buttons={
        <>
          <DiscoButton variant={"outlined"} onClick={modal.close} color={"grey"}>
            {"Cancel"}
          </DiscoButton>

          <Form.SubmitButton
            form={form}
            disabled={form.disabled || form.isSubmitting || isLoading}
            shouldDisplaySpinner={form.isSubmitting || isLoading}
          >
            {"Connect"}
          </Form.SubmitButton>
        </>
      }
    >
      <DiscoFormControl
        error={Boolean(form.errorsByField.currency)}
        errorMessages={form.errorsByField.currency}
        tooltip={"Currency cannot be changed after initial setup."}
      >
        <DiscoSelect
          value={form.state.currency}
          onChange={handleCurrencySelect}
          options={activeOrganization.currencies.map((currency) => ({
            value: currency,
            title: `${currency.toUpperCase()} - ${CURRENCY_LABELS[currency]}`,
          }))}
          disableClearable
          autoComplete
          renderOption={currencyLabel}
          renderValue={currencyLabel}
        />

        <div className={classes.tooltip}>
          <DiscoIcon icon={"info"} />

          <DiscoText color={"text.secondary"} variant={"body-xs"}>
            {"Currency cannot be changed after it is set."}
          </DiscoText>
        </div>
      </DiscoFormControl>
    </Form>
  )

  function handleCurrencySelect(value: OrganizationCurrency | null) {
    if (!value) return
    form.state.currency = value
  }

  function currencyLabel(option: { value: OrganizationCurrency; title: string }) {
    return (
      <DiscoText>
        <span className={classes.currencyCode}>{option.value.toUpperCase()}</span>
        {` - ${CURRENCY_LABELS[option.value]}`}
      </DiscoText>
    )
  }

  async function handleSubmit() {
    setIsLoading(true)
    const { response, didSave } = await form.submit(form.state, {
      variables: {
        returnTo: `${window.location.origin}${window.location.pathname}`,
      },
    })
    if (!didSave || !response?.node?.stripeOauthAuthorizeUrl) {
      setIsLoading(false)
      return
    }
    // Open the Stripe oauth url, this URL takes a long time to load, so we don't want to stop the
    // loading state, since it will dismiss immediately before we are actually redirected to Stripe.
    window.open(response.node.stripeOauthAuthorizeUrl, "_self")
  }
}

const useStyles = makeUseStyles((theme) => ({
  currencyCode: {
    ...theme.typography.modifiers.fontWeight[600],
  },
  tooltip: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: theme.spacing(2),
  },
}))

export function ConnectStripeModalContentSkeleton() {
  const classes = useStyles()
  return (
    <>
      <DiscoSelectSkeleton />
      <div className={classes.tooltip}>
        <DiscoIcon icon={"info"} />
        <DiscoText color={"text.secondary"} variant={"body-xs"}>
          {"Currency cannot be changed after it is set."}
        </DiscoText>
      </div>
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(ConnectStripeModalContent),
  skeleton: ConnectStripeModalContentSkeleton,
})
