import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import FormStore from "@/core/form/store/FormStore"
import { UpgradeCheckoutVersionModalContentMutation } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalContentMutation.graphql"
import { UpgradeCheckoutVersionModalDiscounts_PaginationFragment$key } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalDiscounts_PaginationFragment.graphql"
import { UpgradeCheckoutVersionModalDiscountsPaginationQuery } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalDiscountsPaginationQuery.graphql"
import { UpgradeCheckoutVersionModalDiscountsQuery } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalDiscountsQuery.graphql"
import DiscountTableRow, {
  DiscountTableRowSkeleton,
} from "@/payment/stripe-integration/upgrade/DiscountTableRow"
import DiscountTableSelectAll from "@/payment/stripe-integration/upgrade/DiscountTableSelectAll"
import { UpgradeCheckoutVersionModalContentState } from "@/payment/stripe-integration/upgrade/UpgradeCheckoutVersionModalContent"
import Relay from "@/relay/relayUtils"
import {
  DiscoCheckboxSkeleton,
  DiscoModalProps,
  DiscoTable,
  DiscoTableSkeletonWithHeader,
} from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

const DISCOUNTS_PER_PAGE = 10

type Props = Pick<DiscoModalProps, "onClose"> &
  TestIDProps & {
    form: FormStore<
      UpgradeCheckoutVersionModalContentState,
      UpgradeCheckoutVersionModalContentMutation
    >
  }

function UpgradeCheckoutVersionModalDiscounts({
  testid = "UpgradeCheckoutVersionModalDiscounts",
  form,
}: Props) {
  const activeOrganization = useActiveOrganization()
  const [activePage, setActivePage] = useState(1)

  const { organization } = useLazyLoadQuery<UpgradeCheckoutVersionModalDiscountsQuery>(
    graphql`
      query UpgradeCheckoutVersionModalDiscountsQuery(
        $organizationId: ID!
        $first: Int
        $after: String
        $last: Int
        $before: String
      ) {
        organization: node(id: $organizationId) {
          ... on Organization {
            ...UpgradeCheckoutVersionModalDiscounts_PaginationFragment
              @arguments(first: $first, after: $after, last: $last, before: $before)
          }
        }
      }
    `,
    {
      organizationId: activeOrganization?.id || "",
      first: DISCOUNTS_PER_PAGE,
    },
    { fetchPolicy: "network-only" }
  )

  const { data, refetch } = usePaginationFragment<
    UpgradeCheckoutVersionModalDiscountsPaginationQuery,
    UpgradeCheckoutVersionModalDiscounts_PaginationFragment$key
  >(
    graphql`
      fragment UpgradeCheckoutVersionModalDiscounts_PaginationFragment on Organization
      @refetchable(queryName: "UpgradeCheckoutVersionModalDiscountsPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
      ) {
        id
        discounts(first: $first, after: $after, last: $last, before: $before)
          @connection(key: "UpgradeCheckoutVersionModalDiscounts_discounts") {
          __id
          totalCount
          edges {
            cursor
            node {
              id
              ...DiscountTableRowFragment
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization
  )

  const discounts = Relay.connectionToArray(data?.discounts)

  return (
    <DiscoTable
      activePage={activePage}
      setActivePage={setActivePage}
      rowsPerPage={DISCOUNTS_PER_PAGE}
      testid={testid}
      onPaginate={refetch}
      rows={discounts.map((d) => (
        <DiscountTableRow key={d.id} discountKey={d} form={form} />
      ))}
      connection={{
        cursorsList: data?.discounts.edges.map((e) => e.cursor) || [],
        totalCount: data?.discounts.totalCount || 0,
        pageInfo: {
          endCursor: data?.discounts.pageInfo.endCursor,
          startCursor: data?.discounts.pageInfo.startCursor,
        },
      }}
      header={getHeaders()}
    />
  )

  function getHeaders() {
    return [
      {
        value: <DiscountTableSelectAll testid={`${testid}.select-all`} form={form} />,
        key: "checkbox",
      },
      { value: "Discount" },
      { value: "Code" },
      { value: "Value" },
      { value: "Max Redemptions" },
      { value: "Redemptions" },
    ]
  }
}

export function UpgradeCheckoutVersionModalDiscountsSkeleton() {
  return (
    <DiscoTableSkeletonWithHeader
      header={[
        { key: "checkbox", value: <DiscoCheckboxSkeleton hideLabel /> },
        { value: "Discount" },
        { value: "Code" },
        { value: "Value" },
        { value: "Max Redemptions" },
        { value: "Redemptions" },
      ]}
      rows={1}
      row={<DiscountTableRowSkeleton />}
    />
  )
}

export default Relay.withSkeleton({
  component: observer(UpgradeCheckoutVersionModalDiscounts),
  skeleton: UpgradeCheckoutVersionModalDiscountsSkeleton,
})
