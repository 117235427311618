/**
 * @generated SignedSource<<de412cb7d2262aab665ee01a330b212c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PaidProductTableRowFragment$data = {
  readonly id: string;
  readonly slug: string;
  readonly type: ProductType;
  readonly " $fragmentSpreads": FragmentRefs<"ProductPricingDetailsFragment" | "ProductTypeTagFragment" | "ProductWithDetailsFragment">;
  readonly " $fragmentType": "PaidProductTableRowFragment";
};
export type PaidProductTableRowFragment$key = {
  readonly " $data"?: PaidProductTableRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PaidProductTableRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaidProductTableRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "hideDrafts",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "hideNonPublic",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProductPricingDetailsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductWithDetailsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductTypeTagFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "6cd8133f97f989703454db0152e2ed88";

export default node;
