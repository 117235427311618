import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import FormStore from "@/core/form/store/FormStore"
import { DiscountTableSelectAllQuery } from "@/payment/stripe-integration/upgrade/__generated__/DiscountTableSelectAllQuery.graphql"
import { UpgradeCheckoutVersionModalContentMutation } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalContentMutation.graphql"
import { UpgradeCheckoutVersionModalContentState } from "@/payment/stripe-integration/upgrade/UpgradeCheckoutVersionModalContent"
import { NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCheckbox, DiscoSpinner } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

type DiscountNode = NodeFromConnection<
  NonNullable<DiscountTableSelectAllQuery["response"]["organization"]>["discounts"]
>

type Props = TestIDProps & {
  form: FormStore<
    UpgradeCheckoutVersionModalContentState,
    UpgradeCheckoutVersionModalContentMutation
  >
}

function DiscountTableSelectAll({ testid = "DiscountTableSelectAll", form }: Props) {
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()

  const response = useLazyLoadQuery<DiscountTableSelectAllQuery>(
    graphql`
      query DiscountTableSelectAllQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            discounts {
              totalCount
              edges {
                node {
                  id
                  maxRedemptions
                  redemptions {
                    totalCount
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: activeOrganization?.id || "" },
    { fetchPolicy: "network-only" }
  )

  const discounts = Relay.connectionToArray(response?.organization?.discounts)
  const migratableDiscounts = discounts.filter(isNotFullyRedeemed)
  const isAllSelected =
    (form.state.stripe_acacia?.discountIds?.length || 0) >=
    (migratableDiscounts.length || 0)

  // Select all on initial load
  useEffect(() => {
    handleChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DiscoCheckbox
      testid={`${testid}.select-all`}
      label={null}
      className={classes.checkbox}
      onChange={handleChange}
      checked={isAllSelected}
    />
  )

  function handleChange() {
    if (isAllSelected && form.state.stripe_acacia) {
      form.state.stripe_acacia.discountIds = observable.array([])
      return
    }

    if (form.state.stripe_acacia) {
      form.state.stripe_acacia.discountIds = observable.array(
        migratableDiscounts.map((discount) => discount.id)
      )
    }
  }

  function isNotFullyRedeemed(discount: DiscountNode) {
    if (discount.maxRedemptions === null) return true
    if (discount.redemptions.totalCount < discount.maxRedemptions) return true
    return false
  }
}

const useStyles = makeUseStyles({
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "24px",
    width: "24px",
  },
  checkbox: {
    margin: 0,
    "& > *": {
      padding: 0,
    },
  },
})

function DiscountTableSelectAllSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.spinner}>
      <DiscoSpinner size={"sm"} />
    </div>
  )
}

export default Relay.withSkeleton({
  component: observer(DiscountTableSelectAll),
  skeleton: DiscountTableSelectAllSkeleton,
})
