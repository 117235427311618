import { useGlobalModal } from "@/core/context/GlobalModalProvider"
import { ConnectStripeModalContentSkeleton } from "@/payment/stripe-integration/modal/ConnectStripeModalContent"
import { DiscoModal } from "@disco-ui"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { TestIDProps } from "@utils/typeUtils"

const ConnectStripeModalContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "connect-stripe-modal-content" */ "@/payment/stripe-integration/modal/ConnectStripeModalContent"
    )
)

type Props = TestIDProps

function ConnectStripeModal({ testid = "ConnectStripeGlobalModal" }: Props) {
  const { connectStripeAcacia } = useFeatureFlags()
  const modal = useGlobalModal("connectStripe")
  const { connectStripeModal } = modal.params
  const shouldRenderModal = connectStripeModal === "1" && connectStripeAcacia
  if (!shouldRenderModal) return null

  return (
    <DiscoModal
      isOpen={modal.isOpen}
      onClose={modal.close}
      testid={`${testid}.modal`}
      modalContentLabel={"Connect Stripe"}
      title={"Select your currency"}
      subtitle={"Select the currency you would like to charge in."}
      body={<ConnectStripeModalContent />}
      skeletonBody={<ConnectStripeModalContentSkeleton />}
      buttons
    />
  )
}

export default ConnectStripeModal
