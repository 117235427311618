/**
 * @generated SignedSource<<9a028b31ecc64d5e45175f00d8d65d03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostPreviewEmailFormFieldsFragment$data = {
  readonly content: {
    readonly id: string;
    readonly notificationEmailSentDatetime: string | null;
  };
  readonly feedId: string;
  readonly id: string;
  readonly product: {
    readonly id: string;
    readonly name: string;
    readonly slug: string;
  } | null;
  readonly " $fragmentType": "PostPreviewEmailFormFieldsFragment";
};
export type PostPreviewEmailFormFieldsFragment$key = {
  readonly " $data"?: PostPreviewEmailFormFieldsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostPreviewEmailFormFieldsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostPreviewEmailFormFieldsFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feedId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notificationEmailSentDatetime",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();

(node as any).hash = "558958690efb6577520b934a0c135801";

export default node;
