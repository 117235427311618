import { OrganizationCheckoutVersion } from "@/admin/appearance/__generated__/AdminAppearancePageMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalModal } from "@/core/context/GlobalModalProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { ObservableState, useFormStore } from "@/core/form/store/FormStore"
import {
  UpgradeCheckoutVersionInput,
  UpgradeCheckoutVersionModalContentMutation,
  UpgradeToStripeAcaciaInput,
} from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalContentMutation.graphql"
import UpgradeCheckoutVersionModalDiscounts, {
  UpgradeCheckoutVersionModalDiscountsSkeleton,
} from "@/payment/stripe-integration/upgrade/UpgradeCheckoutVersionModalDiscounts"
import UpgradeCheckoutVersionModalProducts, {
  UpgradeCheckoutVersionModalProductsSkeleton,
} from "@/payment/stripe-integration/upgrade/UpgradeCheckoutVersionModalProducts"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { graphql } from "react-relay"

export type UpgradeCheckoutVersionModalContentState = Omit<
  UpgradeCheckoutVersionInput,
  "stripe_acacia"
> & {
  stripe_acacia: ObservableState<UpgradeToStripeAcaciaInput>
  totalPaidProducts: number
}

type Props = TestIDProps

function UpgradeCheckoutVersionModalContent({
  testid = "UpgradeCheckoutVersionModalContent",
}: Props) {
  const modal = useGlobalModal("upgradeCheckoutVersion")
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const experienceLabel = useLabel("admin_experience")
  const { step } = modal.params

  const form = useFormStore<
    UpgradeCheckoutVersionModalContentMutation,
    UpgradeCheckoutVersionModalContentState
  >(
    graphql`
      mutation UpgradeCheckoutVersionModalContentMutation(
        $input: UpgradeCheckoutVersionInput!
      ) {
        response: upgradeCheckoutVersion(input: $input) {
          node {
            id
            hasStripeConnection
            checkoutVersion
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      version: getUpgradeVersion(),
      stripe_acacia: {
        discountIds: observable.array([]),
      },
      totalPaidProducts: 0,
    },
    { requireChangeToSubmit: false }
  )

  return (
    <Form
      id={testid}
      testid={testid}
      onSubmit={handleSubmit}
      buttons={
        <>
          <DiscoButton color={"grey"} variant={"outlined"} onClick={handlePreviousClick}>
            {renderPreviousStep()}
          </DiscoButton>

          <DiscoButton
            onClick={handleNextClick}
            shouldDisplaySpinner={form.isSubmitting}
            disabled={form.disabled}
          >
            {renderNextStep()}
          </DiscoButton>
        </>
      }
    >
      {renderBody()}
    </Form>
  )

  function getUpgradeVersion(): OrganizationCheckoutVersion {
    switch (activeOrganization?.checkoutVersion) {
      case "stripe":
      default:
        return "stripe_acacia"
    }
  }

  function renderNextStep() {
    switch (step) {
      case "products":
        return "Continue"
      case "discounts":
        return "Complete"
      default:
        return null
    }
  }

  function renderPreviousStep() {
    switch (step) {
      case "products":
        return "Cancel"
      case "discounts":
        return "Back"
      default:
        return null
    }
  }

  function renderBody() {
    switch (step) {
      case "products":
        return <UpgradeCheckoutVersionModalProducts form={form} />
      case "discounts":
        return <UpgradeCheckoutVersionModalDiscounts form={form} />
      default:
        return null
    }
  }

  async function handleNextClick() {
    switch (step) {
      case "products":
        modal.open({ step: "discounts" })
        break
      case "discounts":
        await handleSubmit()
        break
    }
  }

  function handlePreviousClick() {
    switch (step) {
      case "products":
        modal.close()
        break
      case "discounts":
        modal.open({ step: "products" })
        break
    }
  }

  async function handleSubmit() {
    const { didSave } = await form.submit({
      version: form.state.version,
      stripe_acacia: form.state.stripe_acacia,
    })
    if (!didSave) return

    modal.close()

    const productsCreated = form.state.totalPaidProducts
    const discountsCreated = form.state.stripe_acacia?.discountIds.length || 0

    const productsLabel = pluralize(experienceLabel.singular, productsCreated, true)
    const discountsLabel = pluralize("Discount", discountsCreated, true)

    displaySuccessToast({
      message: "Stripe integration has been upgraded.",
      body:
        // No products or discounts created
        !productsCreated && !discountsCreated ? (
          "You have successfully upgraded your Stripe integration."
        ) : // Only products created
        productsCreated && !discountsCreated ? (
          <DiscoText color={"groovy.green.600"}>
            <span className={classes.bold}>{productsLabel}</span>
            {` ${
              productsCreated === 1 ? "was" : "were"
            } successfully created in Stripe for you.`}
          </DiscoText>
        ) : // Only discounts created
        !productsCreated && discountsCreated ? (
          <DiscoText color={"groovy.green.600"}>
            <span className={classes.bold}>{discountsLabel}</span>
            {` ${
              discountsCreated === 1 ? "was" : "were"
            } successfully created in Stripe for you.`}
          </DiscoText>
        ) : (
          // Both products and discounts created
          <DiscoText color={"groovy.green.600"}>
            <span className={classes.bold}>{productsLabel}</span>
            {` and `}
            <span className={classes.bold}>{discountsLabel}</span>
            {` ${
              discountsCreated === 1 ? "was" : "were"
            } successfully created in Stripe for you.`}
          </DiscoText>
        ),
    })
  }
}

const useStyles = makeUseStyles({
  bold: {
    fontWeight: 700,
  },
})

export function UpgradeCheckoutVersionModalContentSkeleton() {
  const modal = useGlobalModal("upgradeCheckoutVersion")
  const { step } = modal.params
  return (
    <Form
      buttons={
        <>
          <DiscoButton color={"grey"} variant={"outlined"}>
            {"Cancel"}
          </DiscoButton>
          <DiscoButton disabled>{"Continue"}</DiscoButton>
        </>
      }
    >
      {step === "products" ? (
        <UpgradeCheckoutVersionModalProductsSkeleton />
      ) : (
        <UpgradeCheckoutVersionModalDiscountsSkeleton />
      )}
    </Form>
  )
}

export default Relay.withSkeleton({
  component: observer(UpgradeCheckoutVersionModalContent),
  skeleton: UpgradeCheckoutVersionModalContentSkeleton,
})
