import { useGlobalModal } from "@/core/context/GlobalModalProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { UpgradeCheckoutVersionModalContentSkeleton } from "@/payment/stripe-integration/upgrade/UpgradeCheckoutVersionModalContent"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoModal, DiscoText } from "@disco-ui"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import useTheme from "@material-ui/core/styles/useTheme"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { TestIDProps } from "@utils/typeUtils"

const UpgradeCheckoutVersionModalContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "upgrade-checkout-version-modal-content" */ "@/payment/stripe-integration/upgrade/UpgradeCheckoutVersionModalContent"
    )
)

type Props = TestIDProps

function UpgradeCheckoutVersionModal({ testid = "UpgradeCheckoutVersion" }: Props) {
  const modal = useGlobalModal("upgradeCheckoutVersion")
  const { connectStripeAcacia } = useFeatureFlags()
  const { upgradeCheckoutVersionModal, step } = modal.params
  const theme = useTheme()
  const classes = useStyles()
  const experienceLabel = useLabel("admin_experience")
  const shouldRenderModal = upgradeCheckoutVersionModal === "1" && connectStripeAcacia
  if (!shouldRenderModal) return null

  return (
    <DiscoModal
      testid={`${testid}.modal`}
      isOpen={modal.isOpen}
      onClose={modal.close}
      width={`${theme.measure.modalMaxWidth.large}px`}
      modalContentLabel={"Upgrade Checkout Version"}
      title={renderTitle()}
      body={<UpgradeCheckoutVersionModalContent />}
      skeletonBody={<UpgradeCheckoutVersionModalContentSkeleton />}
      classes={{ container: classes.modal }}
      buttons
    />
  )

  function renderTitle() {
    switch (step) {
      case "products":
        return (
          <>
            <div className={classes.title}>
              <DiscoText variant={"body-lg-600"}>
                {`Review your ${experienceLabel.plural} for Stripe`}
              </DiscoText>

              <DiscoTag
                name={"1/2"}
                color={theme.palette.groovy.blue[400]}
                backgroundColor={theme.palette.groovy.blue[100]}
              />
            </div>

            <DiscoText color={"text.secondary"}>
              {`Here are the paid ${experienceLabel.plural} from your community that we'll automatically create as products in your Stripe account.`}
            </DiscoText>
          </>
        )
      case "discounts":
        return (
          <>
            <div className={classes.title}>
              <DiscoText variant={"body-lg-600"}>
                {"Select Discounts to Transfer to Stripe"}
              </DiscoText>

              <DiscoTag
                name={"2/2"}
                color={theme.palette.groovy.blue[400]}
                backgroundColor={theme.palette.groovy.blue[100]}
              />
            </div>

            <DiscoText color={"text.secondary"} marginBottom={2}>
              {
                "Discounts will now be managed exclusively in Stripe. Select the discounts you want to transfer to your Stripe account to prevent losing them."
              }
            </DiscoText>

            <DiscoText color={"text.secondary"}>
              <span className={classes.warningText}>{`WARNING: `}</span>
              {
                "After completing this process, all discounts will be removed from Disco and will no longer be accessible. This action cannot be undone."
              }
            </DiscoText>
          </>
        )
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  modal: {
    textAlign: "left",
  },
  warningText: {
    color: theme.palette.text.danger,
    fontWeight: 700,
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

export default UpgradeCheckoutVersionModal
