/**
 * @generated SignedSource<<7d31c43e4102bbab398ed53952010f4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PinPostInput = {
  dashboardId?: string | null;
  feedId?: string | null;
  postId: string;
};
export type PinPostButtonMutation$variables = {
  dashboardId?: string | null;
  feedId?: string | null;
  input: PinPostInput;
};
export type PinPostButtonMutation$data = {
  readonly pinPost: {
    readonly node: {
      readonly id: string;
      readonly pinnedPost: {
        readonly id: string;
      } | null;
    } | null;
  };
};
export type PinPostButtonMutation = {
  response: PinPostButtonMutation$data;
  variables: PinPostButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dashboardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "feedId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PinPostResponse",
    "kind": "LinkedField",
    "name": "pinPost",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "dashboardId",
                "variableName": "dashboardId"
              },
              {
                "kind": "Variable",
                "name": "feedId",
                "variableName": "feedId"
              }
            ],
            "concreteType": "PinnedPost",
            "kind": "LinkedField",
            "name": "pinnedPost",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PinPostButtonMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PinPostButtonMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "821924fbe08b970719c93ae704b86149",
    "id": null,
    "metadata": {},
    "name": "PinPostButtonMutation",
    "operationKind": "mutation",
    "text": "mutation PinPostButtonMutation(\n  $input: PinPostInput!\n  $feedId: ID\n  $dashboardId: ID\n) {\n  pinPost(input: $input) {\n    node {\n      id\n      pinnedPost(feedId: $feedId, dashboardId: $dashboardId) {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a864b261afcfd30a4e976b067570fcd7";

export default node;
