/**
 * @generated SignedSource<<bdeb5babbcfb039586f571f9ccd45374>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPostButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "EditPostButtonFragment";
};
export type EditPostButtonFragment$key = {
  readonly " $data"?: EditPostButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditPostButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditPostButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Post",
  "abstractKey": null
};

(node as any).hash = "c0cc310789b6d14265cc2d1794d03604";

export default node;
