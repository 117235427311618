import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { PaidProductTableRowFragment$key } from "@/payment/stripe-integration/upgrade/__generated__/PaidProductTableRowFragment.graphql"
import { UpgradeCheckoutVersionModalContentMutation } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalContentMutation.graphql"
import { UpgradeCheckoutVersionModalContentState } from "@/payment/stripe-integration/upgrade/UpgradeCheckoutVersionModalContent"
import ProductPricingDetails, {
  ProductPricingDetailsSkeleton,
} from "@/pricing/ProductPricingDetails"
import ProductWithDetails, {
  ProductWithDetailsSkeleton,
} from "@/product/common/ProductWithDetails"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoModalProps, DiscoTable, DiscoTooltip } from "@disco-ui"
import DiscoTag, { DiscoTagSkeleton } from "@disco-ui/tag/DiscoTag"
import { TableCell, useTheme } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = Pick<DiscoModalProps, "onClose"> & {
  productKey: PaidProductTableRowFragment$key
  form: FormStore<
    UpgradeCheckoutVersionModalContentState,
    UpgradeCheckoutVersionModalContentMutation
  >
}

function PaidProductTableRow(props: Props) {
  const { productKey } = props
  const classes = useStyles()
  const theme = useTheme()
  const experienceLabel = useLabel("admin_experience")

  const product = useFragment<PaidProductTableRowFragment$key>(
    graphql`
      fragment PaidProductTableRowFragment on Product {
        id
        slug
        type
        ...ProductPricingDetailsFragment
          @arguments(hideNonPublic: false, hideDrafts: false)
        ...ProductWithDetailsFragment
        ...ProductTypeTagFragment
      }
    `,
    productKey
  )

  const testid = `PaidProductTableRow.${product.slug}`

  return (
    <DiscoTable.Row
      testid={testid}
      className={product.type === "membership_plan" ? classes.disabled : undefined}
    >
      <TableCell className={classes.cell}>
        <ProductWithDetails productKey={product} testid={testid} />

        <DiscoTooltip
          content={`This membership plan already exists on Stripe.`}
          disabled={product.type !== "membership_plan"}
        />
      </TableCell>

      <TableCell>
        <DiscoTag
          name={
            product.type === "membership_plan"
              ? "Membership Plan"
              : experienceLabel.singular
          }
          backgroundColor={theme.palette.groovy.grey[200]}
          testid={testid}
        />
      </TableCell>

      <TableCell>
        <ProductPricingDetails
          productKey={product}
          ignoreViewersPlan
          classes={{
            price: classes.price,
            details: classes.details,
          }}
        />
      </TableCell>
    </DiscoTable.Row>
  )
}

const useStyles = makeUseStyles((theme) => ({
  price: {
    ...theme.typography["body-sm"],
    fontWeight: 600,
  },
  details: {
    ...theme.typography["body-xs"],
  },
  cell: {
    display: "flex",
    alignItems: "center",
  },
  disabled: {
    cursor: "not-allowed",
    userSelect: "none",
    opacity: 0.4,
  },
}))

export const PaidProductTableRowSkeleton = () => {
  return (
    <DiscoTable.Row>
      <TableCell>
        <ProductWithDetailsSkeleton />
      </TableCell>

      <TableCell>
        <DiscoTagSkeleton />
      </TableCell>

      <TableCell>
        <ProductPricingDetailsSkeleton />
      </TableCell>
    </DiscoTable.Row>
  )
}

export default Relay.withSkeleton({
  component: observer(PaidProductTableRow),
  skeleton: PaidProductTableRowSkeleton,
})
