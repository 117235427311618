import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import FormStore from "@/core/form/store/FormStore"
import { UpgradeCheckoutVersionModalContentMutation } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalContentMutation.graphql"
import { UpgradeCheckoutVersionModalProducts_PaginationFragment$key } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalProducts_PaginationFragment.graphql"
import { UpgradeCheckoutVersionModalProductsPaginationQuery } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalProductsPaginationQuery.graphql"
import { UpgradeCheckoutVersionModalProductsQuery } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalProductsQuery.graphql"
import PaidProductTableRow, {
  PaidProductTableRowSkeleton,
} from "@/payment/stripe-integration/upgrade/PaidProductTableRow"
import { UpgradeCheckoutVersionModalContentState } from "@/payment/stripe-integration/upgrade/UpgradeCheckoutVersionModalContent"
import Relay from "@/relay/relayUtils"
import { DiscoModalProps, DiscoTable, DiscoTableSkeletonWithHeader } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect, useState } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

const PRODUCTS_PER_PAGE = 10

type Props = TestIDProps &
  Pick<DiscoModalProps, "onClose"> & {
    form: FormStore<
      UpgradeCheckoutVersionModalContentState,
      UpgradeCheckoutVersionModalContentMutation
    >
  }

function UpgradeCheckoutVersionModalProducts({
  testid = "UpgradeCheckoutVersionModalProducts",
  form,
}: Props) {
  const activeOrganization = useActiveOrganization()
  const [activePage, setActivePage] = useState(1)
  const { organization } = useLazyLoadQuery<UpgradeCheckoutVersionModalProductsQuery>(
    graphql`
      query UpgradeCheckoutVersionModalProductsQuery(
        $organizationId: ID!
        $first: Int
        $after: String
        $last: Int
        $before: String
      ) {
        organization: node(id: $organizationId) {
          ... on Organization {
            discounts {
              totalCount
            }
            ...UpgradeCheckoutVersionModalProducts_PaginationFragment
              @arguments(first: $first, after: $after, last: $last, before: $before)
          }
        }
      }
    `,
    {
      organizationId: activeOrganization?.id || "",
      first: PRODUCTS_PER_PAGE,
    },
    { fetchPolicy: "network-only" }
  )

  const { data, refetch } = usePaginationFragment<
    UpgradeCheckoutVersionModalProductsPaginationQuery,
    UpgradeCheckoutVersionModalProducts_PaginationFragment$key
  >(
    graphql`
      fragment UpgradeCheckoutVersionModalProducts_PaginationFragment on Organization
      @refetchable(queryName: "UpgradeCheckoutVersionModalProductsPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
      ) {
        id
        paidProducts(
          types: [course, membership_plan]
          first: $first
          after: $after
          last: $last
          before: $before
        ) @connection(key: "UpgradeCheckoutVersionModalProductsTable_paidProducts") {
          __id
          totalCount
          edges {
            cursor
            node {
              id
              ...PaidProductTableRowFragment
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization
  )

  const paidProducts = Relay.connectionToArray(data?.paidProducts)

  useEffect(() => {
    form.state.totalPaidProducts = data?.paidProducts.totalCount || 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.paidProducts.totalCount])

  return (
    <DiscoTable
      activePage={activePage}
      setActivePage={setActivePage}
      rowsPerPage={PRODUCTS_PER_PAGE}
      testid={testid}
      onPaginate={refetch}
      rows={paidProducts.map((p) => (
        <PaidProductTableRow key={p.id} productKey={p} form={form} />
      ))}
      connection={{
        cursorsList: data?.paidProducts.edges.map((e) => e.cursor) || [],
        totalCount: data?.paidProducts.totalCount || 0,
        pageInfo: {
          endCursor: data?.paidProducts.pageInfo.endCursor,
          startCursor: data?.paidProducts.pageInfo.startCursor,
        },
      }}
      header={getHeaders()}
    />
  )

  function getHeaders() {
    return [{ value: "Name" }, { value: "Type" }, { value: "Price" }]
  }
}

export function UpgradeCheckoutVersionModalProductsSkeleton() {
  return (
    <DiscoTableSkeletonWithHeader
      header={[{ value: "Name" }, { value: "Type" }, { value: "Price" }]}
      rows={1}
      row={<PaidProductTableRowSkeleton />}
    />
  )
}

export default Relay.withSkeleton({
  component: UpgradeCheckoutVersionModalProducts,
  skeleton: UpgradeCheckoutVersionModalProductsSkeleton,
})
