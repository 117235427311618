import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { ConnectStripeButtonQuery } from "@/payment/stripe-integration/button/__generated__/ConnectStripeButtonQuery.graphql"
import Relay from "@/relay/relayUtils"
import { useOrganizationRoleLabel } from "@/role/roleUtils"
import { DiscoButton, DiscoButtonSkeleton, DiscoTooltip } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { Link } from "@material-ui/core"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { graphql } from "react-relay"

interface ConnectStripeButtonProps extends TestIDProps {
  showConnectConfirmDialog?: boolean
}

function ConnectStripeButton({
  showConnectConfirmDialog,
  testid = "ConnectStripeButton",
}: ConnectStripeButtonProps) {
  const activeOrganization = useActiveOrganization()!
  const [showConnectModal, setShowConnectModal] = useState(false)
  const ownerLabel = useOrganizationRoleLabel("owner")
  const integrationDrawer = useGlobalDrawer("integration")
  const experienceDrawer = useGlobalDrawer("experienceSettings")
  const { connectStripeAcacia } = useFeatureFlags()
  const canManage = activeOrganization.viewerPermissions.has("stripe_integration.manage")
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <DiscoTooltip
        content={`Only community ${ownerLabel.plural} can connect Stripe`}
        disabled={canManage}
      >
        <div>
          <DiscoButton
            component={Link}
            testid={`${testid}.stripe-connection-button`}
            onClick={connectStripeAcacia ? openDrawer : handleConnectButtonClick}
            disabled={!canManage || isLoading}
            shouldDisplaySpinner={isLoading}
          >
            {"Connect Stripe"}
          </DiscoButton>
        </div>
      </DiscoTooltip>

      <DiscoWarningModal
        icon={"warning"}
        variant={"primary"}
        testid={"ConnectStripe"}
        isOpen={showConnectModal}
        confirmationButtonProps={{
          onClick: redirectToStripe,
          children: "Continue to Stripe",
        }}
        onClose={() => {
          setShowConnectModal(false)
        }}
        modalContentLabel={"Continue to Stripe"}
        title={"Continue to Stripe?"}
        description={
          "You'll be redirected to Stripe to continue with the connection process. Before continuing, please save any changes you've made."
        }
      />
    </>
  )

  function openDrawer() {
    if (experienceDrawer.isOpen) {
      experienceDrawer.swap<"integration">({ integration: "stripe", mode: "connect" })
    } else {
      integrationDrawer.open({ integration: "stripe", mode: "connect" })
    }
  }

  async function handleConnectButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (showConnectConfirmDialog) setShowConnectModal(true)
    else await redirectToStripe(e)
  }

  async function redirectToStripe(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    e.preventDefault()
    setIsLoading(true)

    // Run the query to fetch the oauth url
    try {
      const response = await Relay.runQuery<ConnectStripeButtonQuery>(
        graphql`
          query ConnectStripeButtonQuery($id: ID!, $returnTo: String!) {
            organization: node(id: $id) {
              ... on Organization {
                stripeOauthAuthorizeUrl(returnTo: $returnTo)
              }
            }
          }
        `,
        {
          id: activeOrganization.id,
          returnTo: window.location.href,
        }
      )
      if (!response?.organization?.stripeOauthAuthorizeUrl) return

      // Close the modal and open the Stripe oauth url, this URL takes a long time to load,
      // so we don't want to stop the loading state, since it will dismiss immediately before
      // we are actually redirected to Stripe. So instead set the loading state to false in
      // case of an error, since the user will be redirected back to Disco and this component
      // will be rerendered with the loading state set to false.
      setShowConnectModal(false)
      window.open(response.organization.stripeOauthAuthorizeUrl, "_self")
    } catch (error) {
      setIsLoading(false)
    }
  }
}

const ConnectStripeButtonSkeleton = () => {
  return <DiscoButtonSkeleton />
}

export default Relay.withSkeleton({
  component: ConnectStripeButton,
  skeleton: ConnectStripeButtonSkeleton,
})
