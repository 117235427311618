import StripeIcon from "@/core/ui/images/logos/stripe-icon.svg"
import ConnectStripeButton from "@/payment/stripe-integration/button/ConnectStripeButton"
import { Spacing } from "@assets/style/appMuiTheme"
import makeSpacingStyles from "@assets/style/util/makeSpacingStyles"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSection, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface Props extends TestIDProps, Spacing {
  details?: string
}

function ConnectStripeBanner({
  testid = "ConnectStripeBanner",
  details = "Stripe must be connected to collect payments",
  padding = 2,
  ...spacingProps
}: Props) {
  const classes = useStyles()
  const spacingClasses = useSpacingStyles({
    padding,
    ...spacingProps,
  })

  return (
    <DiscoSection border className={spacingClasses.root}>
      <div className={classes.container}>
        <div className={classes.lhs}>
          <StripeIcon width={48} height={48} />

          <div>
            <DiscoText variant={"body-md-600"} marginBottom={0.5}>
              {"Connect Stripe"}
            </DiscoText>

            <DiscoText variant={"body-sm"} color={"text.secondary"}>
              {details}
            </DiscoText>
          </div>
        </div>

        <div>
          <ConnectStripeButton testid={testid} showConnectConfirmDialog />
        </div>
      </div>
    </DiscoSection>
  )
}

const useSpacingStyles = makeSpacingStyles()

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(3),
  },
  lhs: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(3),
  },
}))

export default ConnectStripeBanner
