/**
 * @generated SignedSource<<902ed925006153afe1077aa44ee981db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
export type CreatePostModalQuery$variables = {
  feedId: string;
  organizationId: string;
  productId: string;
  userId: string;
};
export type CreatePostModalQuery$data = {
  readonly feedNode: {
    readonly __typename: "Feed";
    readonly id: string;
    readonly name: string;
    readonly product: {
      readonly id: string;
      readonly name: string;
      readonly slug: string;
      readonly startDate: string | null;
      readonly status: ProductStatus;
      readonly waitingRoomEndsAt: string | null;
    } | null;
    readonly viewerPermissions: ReadonlyArray<string>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly organization: {
    readonly feeds?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly viewerPermissions: ReadonlyArray<string>;
        };
      }>;
    };
    readonly products?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly feeds: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly name: string;
                readonly viewerPermissions: ReadonlyArray<string>;
              };
            }>;
          };
          readonly id: string;
          readonly name: string;
          readonly slug: string;
          readonly startDate: string | null;
          readonly status: ProductStatus;
          readonly waitingRoomEndsAt: string | null;
        };
      }>;
    };
  } | null;
  readonly product: {
    readonly __typename: "Product";
    readonly feeds: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly viewerPermissions: ReadonlyArray<string>;
        };
      }>;
    };
    readonly id: string;
    readonly name: string;
    readonly slug: string;
    readonly startDate: string | null;
    readonly status: ProductStatus;
    readonly waitingRoomEndsAt: string | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly user: {
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
  } | null;
};
export type CreatePostModalQuery = {
  response: CreatePostModalQuery$data;
  variables: CreatePostModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "feedId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerPermissions",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FeedNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Feed",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "canPost",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    }
  ],
  "concreteType": "FeedNodeConnection",
  "kind": "LinkedField",
  "name": "feeds",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": "feeds(canPost:true,first:1)"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "waitingRoomEndsAt",
  "storageKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "course"
        }
      ],
      "concreteType": "ProductNodeConnection",
      "kind": "LinkedField",
      "name": "products",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                (v6/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v9/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "products(type:\"course\")"
    }
  ],
  "type": "Organization",
  "abstractKey": null
},
v15 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "productId"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "FeedNodeConnection",
  "kind": "LinkedField",
  "name": "feeds",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v18 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "feedId"
  }
],
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    (v11/*: any*/),
    (v10/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
},
v20 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePostModalQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v14/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "product",
        "args": (v15/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v11/*: any*/),
              (v10/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v17/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "feedNode",
        "args": (v18/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v19/*: any*/)
            ],
            "type": "Feed",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "user",
        "args": (v20/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileAvatarWithDetailsFragment"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreatePostModalQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          (v14/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "product",
        "args": (v15/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v11/*: any*/),
              (v10/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v17/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "feedNode",
        "args": (v18/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v19/*: any*/)
            ],
            "type": "Feed",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "user",
        "args": (v20/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTest",
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6b0250d6ae3674339477f4d99e0b1851",
    "id": null,
    "metadata": {},
    "name": "CreatePostModalQuery",
    "operationKind": "query",
    "text": "query CreatePostModalQuery(\n  $organizationId: ID!\n  $productId: ID!\n  $feedId: ID!\n  $userId: ID!\n) {\n  organization: node(id: $organizationId) {\n    __typename\n    ... on Organization {\n      feeds(first: 1, canPost: true) {\n        edges {\n          node {\n            id\n            name\n            viewerPermissions\n          }\n        }\n      }\n      products(type: \"course\") {\n        edges {\n          node {\n            id\n            name\n            status\n            slug\n            startDate\n            waitingRoomEndsAt\n            feeds(first: 1, canPost: true) {\n              edges {\n                node {\n                  id\n                  name\n                  viewerPermissions\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n  product: node(id: $productId) {\n    __typename\n    ... on Product {\n      id\n      name\n      slug\n      status\n      startDate\n      waitingRoomEndsAt\n      feeds {\n        edges {\n          node {\n            id\n            name\n            viewerPermissions\n          }\n        }\n      }\n    }\n    id\n  }\n  feedNode: node(id: $feedId) {\n    __typename\n    ... on Feed {\n      id\n      name\n      viewerPermissions\n      product {\n        id\n        name\n        slug\n        status\n        startDate\n        waitingRoomEndsAt\n      }\n    }\n    id\n  }\n  user: node(id: $userId) {\n    __typename\n    ... on User {\n      ...ProfileAvatarWithDetailsFragment\n    }\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "2df08f0be4665b36d0bbd0ad794c15d1";

export default node;
