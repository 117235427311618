/**
 * @generated SignedSource<<1d13295a994f9aa5bd8a55c143fe2f6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeletePostButtonFragment$data = {
  readonly feedId: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "DeletePostButtonFragment";
};
export type DeletePostButtonFragment$key = {
  readonly " $data"?: DeletePostButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeletePostButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeletePostButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feedId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Post",
  "abstractKey": null
};

(node as any).hash = "6f5aed9f88b97aa599ce80f8873a763e";

export default node;
