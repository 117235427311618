/**
 * @generated SignedSource<<b44baf3e421577b2e33a1e06324d8586>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PathwayPageHeaderFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PathwayAdminDropdownFragment">;
  readonly " $fragmentType": "PathwayPageHeaderFragment";
};
export type PathwayPageHeaderFragment$key = {
  readonly " $data"?: PathwayPageHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PathwayPageHeaderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PathwayPageHeaderFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PathwayAdminDropdownFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "47d50a3881a592c2de9bc2b9c0f46ab3";

export default node;
