import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import FormStore from "@/core/form/store/FormStore"
import Discount from "@/discount/Discount"
import { DiscountTableRowFragment$key } from "@/payment/stripe-integration/upgrade/__generated__/DiscountTableRowFragment.graphql"
import { UpgradeCheckoutVersionModalContentMutation } from "@/payment/stripe-integration/upgrade/__generated__/UpgradeCheckoutVersionModalContentMutation.graphql"
import { UpgradeCheckoutVersionModalContentState } from "@/payment/stripe-integration/upgrade/UpgradeCheckoutVersionModalContent"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoCheckbox,
  DiscoCheckboxSkeleton,
  DiscoModalProps,
  DiscoTable,
  DiscoText,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import { TableCell } from "@material-ui/core"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = Pick<DiscoModalProps, "onClose"> & {
  discountKey: DiscountTableRowFragment$key
  form: FormStore<
    UpgradeCheckoutVersionModalContentState,
    UpgradeCheckoutVersionModalContentMutation
  >
}

function DiscountTableRow({ discountKey, form }: Props) {
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()

  const discount = useFragment<DiscountTableRowFragment$key>(
    graphql`
      fragment DiscountTableRowFragment on Discount {
        id
        name
        code
        maxRedemptions
        redemptions {
          totalCount
        }
        ...Discount_displayValue
      }
    `,
    discountKey
  )

  const testid = `DiscountTableRow.${discount.id}`
  const displayValue = Discount.useDisplayValue(discount, activeOrganization?.currency)
  const isSelected = form.state.stripe_acacia?.discountIds?.includes(discount.id) || false
  const isFullyRedeemed =
    discount.maxRedemptions === null
      ? false
      : discount.redemptions.totalCount >= discount.maxRedemptions

  return (
    <DiscoTable.Row testid={testid}>
      <TableCell width={"1%"}>
        <DiscoTooltip
          content={
            "This discount has been fully redeemed, so it will not be recreated on Stripe."
          }
          disabled={!isFullyRedeemed}
        >
          <div>
            <DiscoCheckbox
              className={classes.checkbox}
              label={null}
              onChange={toggleSelect}
              checked={isSelected}
              testid={`${testid}.checkbox`}
              disabled={isFullyRedeemed}
            />
          </div>
        </DiscoTooltip>
      </TableCell>

      <TableCell>
        <DiscoText variant={"body-sm"}>{discount.name}</DiscoText>
      </TableCell>

      <TableCell>
        <DiscoText variant={"body-sm"}>{discount.code}</DiscoText>
      </TableCell>

      <TableCell>
        <DiscoText variant={"body-sm"}>{displayValue}</DiscoText>
      </TableCell>

      <TableCell>
        <DiscoText variant={"body-sm"}>
          {discount.maxRedemptions === null ? "-" : discount.maxRedemptions}
        </DiscoText>
      </TableCell>

      <TableCell>
        <DiscoText variant={"body-sm"}>
          {discount.maxRedemptions === null ? "-" : discount.redemptions.totalCount}
        </DiscoText>
      </TableCell>
    </DiscoTable.Row>
  )

  function toggleSelect() {
    const discountIds = form.state.stripe_acacia?.discountIds || observable.array([])
    const discountIndex = discountIds.findIndex(
      (discountId) => discountId === discount.id
    )

    if (discountIndex === -1) {
      discountIds.push(discount.id)
    } else {
      discountIds.splice(discountIndex, 1)
    }
  }
}

const useStyles = makeUseStyles({
  checkbox: {
    margin: 0,
    "& > *": {
      padding: 0,
    },
  },
})

export const DiscountTableRowSkeleton = () => {
  const classes = useStyles()
  return (
    <DiscoTable.Row>
      <TableCell width={"1%"}>
        <DiscoCheckboxSkeleton hideLabel className={classes.checkbox} />
      </TableCell>

      <TableCell>
        <DiscoTextSkeleton />
      </TableCell>

      <TableCell>
        <DiscoTextSkeleton />
      </TableCell>

      <TableCell>
        <DiscoTextSkeleton />
      </TableCell>

      <TableCell>
        <DiscoTextSkeleton />
      </TableCell>

      <TableCell>
        <DiscoTextSkeleton />
      </TableCell>
    </DiscoTable.Row>
  )
}

export default Relay.withSkeleton({
  component: observer(DiscountTableRow),
  skeleton: DiscountTableRowSkeleton,
})
